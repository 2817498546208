import {
  ViewButton,
  EditButton,
  PersonButton,
  HistoryButton,
  ForceToChiedimiCCButton,
  ReprocessLeadButton,
} from '../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabel,
  getSort,
  hideSensitiveData,
  qualifiedName,
} from '../Common/DataTable/tableHelpers';
import moment from 'moment';

const NO_EDIT_PERSON_ID_STATUS = ['new', 'notAllowed', 'error'];

const statusName = (row) => {
  if (row.qualified === 'new' || row.survey === 'new') return 'new';
  else if (row.error === '' || !row.error) return 'ok';
  return 'error';
};

const createTableColumns = (
  openViewModal,
  openEditModal,
  openPersonModal,
  openForceToDanteAlert,
  openForceToChiedimiCCAlert,
  openReprocessLeadAlert,
  openHistoryModal,
  campaigns,
  showSensitiveData,
) => {
  const actions = openEditModal
    ? {
        title: 'Action',
        name: 'action',
        width: 170,
        align: 'center',
        formatterComponent: ({ row }) => (
          <ul className="list-inline font-size-20 contact-links mb-0">
            <ViewButton row={row} onPress={openViewModal} />
            <EditButton row={row} onPress={openEditModal} />
            {openPersonModal &&
              !NO_EDIT_PERSON_ID_STATUS.includes(row.personId) && (
                <PersonButton row={row} onPress={openPersonModal} />
              )}
            {openForceToChiedimiCCAlert &&
              row.sentToDante === 'no' &&
              row.qualified === 'qualificationRefused' &&
              !row.sentToChiedimiCC &&
              (row.error === '' || !row.error) && (
                <ForceToChiedimiCCButton
                  row={row}
                  onPress={openForceToChiedimiCCAlert}
                />
              )}
            {openReprocessLeadAlert &&
              (row.qualified === 'error' ||
                row.survey === 'error' ||
                row.personId === 'error') && (
                <ReprocessLeadButton
                  row={row}
                  onPress={openReprocessLeadAlert}
                />
              )}
            {row.qualified !== 'new' && openHistoryModal && (
              <HistoryButton row={row} onPress={openHistoryModal} />
            )}
          </ul>
        ),
        filter: 'disabled',
        sorting: 'disabled',
      }
    : {
        title: 'Action',
        name: 'action',
        hidden: true,
        filter: 'disabled',
        sorting: 'disabled',
      };

  return [
    {
      title: 'Campaign',
      name: 'campaignId',
      getCellValue: (row) => getLabel(row.campaignId, campaigns),
      hidden: true,
    },
    {
      title: 'Name',
      name: 'name',
      formatterComponent: ({ value }) =>
        showSensitiveData ? value : hideSensitiveData(value, 4),
      filter: showSensitiveData ? undefined : 'disabled',
      sorting: showSensitiveData ? undefined : 'disabled',
    },
    {
      title: 'Email',
      name: 'email',
      formatterComponent: ({ value }) =>
        showSensitiveData ? value : hideSensitiveData(value, 4),
      filter: showSensitiveData ? undefined : 'disabled',
      sorting: showSensitiveData ? undefined : 'disabled',
    },
    {
      title: 'Phone',
      name: 'phone',
      formatterComponent: ({ value }) =>
        showSensitiveData ? value : hideSensitiveData(value, 6),
      filter: showSensitiveData ? undefined : 'disabled',
      sorting: showSensitiveData ? undefined : 'disabled',
    },
    {
      title: 'Gender',
      name: 'gender',
      width: 100,
      hidden: true,
    },
    {
      title: 'Birthday',
      name: 'birthday',
      width: 120,
      formatterComponent: ({ value }) =>
        value
          ? showSensitiveData
            ? `${moment(value).format('DD/MM/YYYY')}`
            : hideSensitiveData(value, 4)
          : '',
      filter: 'disabled',
      sorting: 'disabled',
      exportValue: ({ value }) => moment(value).format('DD/MM/YYYY'),
      hidden: true,
    },
    {
      title: 'Fiscal Code',
      name: 'fiscalCode',
      width: 100,
      formatterComponent: ({ value }) =>
        showSensitiveData ? value : hideSensitiveData(value, 4),
      filter: showSensitiveData ? undefined : 'disabled',
      sorting: showSensitiveData ? undefined : 'disabled',
      hidden: true,
    },
    {
      title: 'Zip Code',
      name: 'zipCode',
      width: 100,
      formatterComponent: ({ value }) =>
        showSensitiveData ? value : hideSensitiveData(value, 4),
      filter: showSensitiveData ? undefined : 'disabled',
      sorting: showSensitiveData ? undefined : 'disabled',
      hidden: true,
    },
    {
      title: 'City',
      name: 'city',
      width: 80,
      formatterComponent: ({ value }) =>
        showSensitiveData ? value : hideSensitiveData(value, 4),
      filter: showSensitiveData ? undefined : 'disabled',
      sorting: showSensitiveData ? undefined : 'disabled',
      hidden: true,
    },
    {
      title: 'Status',
      name: 'status',
      width: 100,
      getCellValue: (row) => statusName(row),
      formatterComponent: ({ value }) =>
        value === 'new' ? (
          <span className="badge badge-warning font-size-12">NEW</span>
        ) : value === 'ok' ? (
          <span className="badge badge-success font-size-12">OK</span>
        ) : (
          <span className="badge badge-danger font-size-12">ERROR</span>
        ),
      sorting: 'disabled',
    },
    {
      title: 'Qualified',
      name: 'qualified',
      width: 110,
      formatterComponent: ({ value }) => qualifiedName(value, true),
      filter: (value, filter) => getFilter(qualifiedName(value), filter.value),
      sorting: 'disabled',
    },
    /*
    {
      title: 'Call center',
      name: 'sentToDante',
      width: 100,
      sort: true,
      formatterComponent: ({ value, row }) => callCenterName(row, true),
      csvformatterComponent: ({ value, row }) => callCenterName(row),
      filter: (value, filter) => callCenterName(row),
      sorting: (a, b) => callCenterName(row),
      
      hidden: true,
    },
    */
    {
      title: 'Marketing Consent',
      name: 'marketingConsent',
      width: 120,
      hidden: true,
    },
    {
      title: 'Third Party Consent',
      name: 'thirdPartyConsent',
      width: 120,
      hidden: true,
    },
    {
      title: 'Occupation',
      name: 'occupation',
      width: 120,
      hidden: true,
    },
    {
      title: 'External ID',
      name: 'externalId',
      width: 120,
      hidden: true,
    },
    {
      title: 'Source',
      name: 'source',
      width: 100,
      hidden: true,
    },
    {
      title: 'Phone verified',
      name: 'verifiedPhoneNumber',
      width: 100,
      getCellValue: (row) => (row.verifiedPhoneNumber ? 'yes' : 'no'),
      formatterComponent: ({ value }) =>
        value === 'yes' ? (
          <span className="badge badge-success font-size-12">YES</span>
        ) : (
          <span className="badge badge-warning font-size-12">NO</span>
        ),
      hidden: true,
    },
    {
      title: 'Created At',
      name: 'createdAt',
      width: 120,
      formatterComponent: ({ value }) => `${moment(value).fromNow()}`,
      sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
      filter: (value, filter) =>
        getFilter(`${moment(value).fromNow()}`, filter.value),
      exportValue: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
    },
    actions,
  ];
};

export default createTableColumns;
